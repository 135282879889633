<template>
  <div class="m cart-modal">
    <div class="m__header">
      <h1 class="m__title">Корзина</h1>
      <button class="m__close" type="button" @click="$emit('close')">
        <IconComponent category="linear" name="add" />
      </button>
    </div>
    <span class="cart-modal__warring">
      Ваш заказ включает в себя одну или несколько <b>электронных</b> книг в формате <b>epub</b>, которую вы
      можете скачать в нашем мобильном приложении после оплаты. Обратите внимание, что это
      <b>не печатная книга</b>. Если у вас есть вопросы, пожалуйста,
      <a href="https://t.me/MyLib_support" target="_blank">свяжитесь с нами</a>
    </span>
    <form class="m__body" @submit.prevent="buy">
      <ul class="cart-modal__list">
        <li class="cart-modal__book" v-for="(book, i) in cart" :key="i">
          <img
            :src="$store.state._env.MEDIA_ENDPOINT + book.img.path"
            :alt="book.img.alt"
            class="cart-modal__book-img"
          />
          <div class="cart-modal__book-content">
            <span class="cart-modal__book-title">{{ book.title }}</span>
            <span class="cart-modal__book-author" v-if="book.author">{{ book.author.title }}</span>
          </div>
          <div class="cart-modal__book-price">
            <span class="cart-modal__book-discount" v-if="book.share && book.share.percent">
              {{ (book.price / 100) * book.share.percent }} ₽
            </span>
            <span>{{ book.price }} ₽</span>
          </div>
          <button type="button" class="cart-modal__book-remove" @click.prevent="toggleCart(book.id)">
            <IconComponent name="loading" category="default" v-if="removing === book.id" />
            <IconComponent category="linear" name="add" v-else />
          </button>
        </li>
      </ul>
      <div class="m__col" v-if="cart.length">
        <button class="btn btn--main" type="submit" :disabled="loading">
          <IconComponent name="loading" category="default" v-if="loading" />
          <span v-show="!loading">Купить</span>
        </button>
      </div>
      <div class="cart-modal__store">
        <a href="https://apps.apple.com/ru/app/mylib/id1631302796?l=en" target="_blank">
          <IconComponent name="app-store" category="default" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.app.bookstore&hl=ru&gl=US" target="_blank">
          <IconComponent name="google-play" category="default" />
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";
import IconComponent from "components/IconComponent.vue";
import PromoModal from "components/modals/components/PromoModal.vue";

export default {
  name: "CartModalComponent",
  data() {
    return {
      loading: false,
      removing: false,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    toggleCart(id) {
      if (!this.removing) {
        this.removing = id;
        this.$store
          .dispatch("toggle_cart", { apollo: this.$apollo, id })
          .then(() => {
            this.$store.dispatch("cart", { apollo: this.$apollo });
          })
          .finally(() => {
            this.removing = false;
          });
      }
    },
    buy() {
      // if (!this.loading) {
      //   this.loading = true;
      //   this.$store.dispatch("buy", { apollo: this.$apollo });
      // }
      this.$store.state._modals.push({
        component: PromoModal,
      });
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/modal.styl"
.cart-modal {
  max-width 580px

  &__list {
    list-style none
    padding 0
    margin 0
    display flex
    width 100%
    flex-direction column
    gap 16px
  }

  &__book {
    display flex
    gap 16px
    align-items center

    &-title {
      font-weight: 700;
      font-size: 1em;
      color: var(--dark);
    }

    &-content {
      display grid
      gap 8px
      align-items start
      justify-content start
    }

    &-author {
      font-weight: 300;
      font-size: 0.875em;
      color: var(--dark);
    }

    &-img {
      width 60px
      height auto
    }

    &-price {
      margin-left auto
      display grid
      font-weight: 700;
      font-size: 1em;
      color: var(--dark);
    }

    &__discount {
      font-size 0.875em
      font-weight 400
      text-decoration line-through
    }
  }

  &__warring {
    padding: 10px 30px
  }

  &__book-remove {
    background none
    border none
    cursor pointer
    width 36px
    height 36px
    display inline-flex
    align-items center
    justify-content center

    .icon {
      width 24px
      height 24px
      transform rotate(45deg)
    }
  }

  &__store {
    display flex
    justify-content space-between
    max-width 250px
    margin: 0 auto
    gap 10px
    height 34px
  }
}
</style>
