<template>
  <div class="social-register">
    <span class="social-register__or-line">или</span>
    <div class="social-register__socials">
      <a class="social-register__socials-item" v-for="(item, i) in socials" :key="i" :href="item.link">
        <IconComponent category="socials" :name="item.icon" />
      </a>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SocialsRegisterComponent",
  components: { IconComponent },
  data() {
    return {
      socials: [
        {
          title: "VK",
          link: this.$store.state._env.MEDIA_ENDPOINT + "/auth/vkontakte",
          icon: "vk",
        },
        {
          title: "Google",
          link: this.$store.state._env.MEDIA_ENDPOINT + "/auth/google",
          icon: "google",
        },
        {
          title: "Apple",
          link: this.$store.state._env.MEDIA_ENDPOINT + "/auth/apple",
          icon: "apple",
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.social-register {
  width 100%
  display flex
  flex-direction column
  gap 20px

  &__or-line {
    display grid
    grid-template-columns 1fr auto 1fr
    align-items center
    gap: 16px
    font-size: .875em
    line-height: 19px;

    &:before,
    &:after {
      content ""
      display block
      height 1px
      background var(--main)
    }
  }

  &__socials {
    display flex
    align-items center
    justify-content center
    gap: 32px

    &-item {
      display flex
      width 48px
      height: 48px

      & svg {
        width 48px
      }
    }
  }
}
</style>
