<template>
  <div class="m promo-modal">
    <div class="m__header">
      <h1 class="m__title">Введите промокод</h1>
      <button class="m__close" type="button" @click="$emit('close')">
        <IconComponent category="linear" name="add" />
      </button>
    </div>
    <form class="m__body" @submit.prevent="">
      <InputComponent
        title="Введите промокод"
        v-model="form.promocode.value"
        :errors="form.promocode.errors"
      />
      <div class="promo-modal__buttons">
        <button @click="skip" class="btn btn--outline">Пропустить</button>
        <button @click="buy" :disabled="loading" class="btn btn--main">
          <IconComponent name="loading" category="default" v-if="loading" />
          <span>Применить</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";

export default {
  name: "PromoModal",
  components: { InputComponent, IconComponent },
  data() {
    return {
      loading: false,
      form: {
        promocode: {
          value: null,
          errors: [],
        },
      },
    };
  },
  methods: {
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    buy() {
      this.loading = true;
      this.resetErrors();
      this.$store
        .dispatch("buy", { apollo: this.$apollo, promocode: this.form.promocode.value })
        .then(() => {
          this.loading = false;
        })
        .catch(({ graphQLErrors }) => {
          this.parseGqlErrors(graphQLErrors);
          this.loading = false;
        });
    },
    skip() {
      this.loading = true;
      this.$store.dispatch("buy", { apollo: this.$apollo }).then(() => {
        this.loading = false;
      });
    },
    parseGqlErrors(graphQLErrors) {
      console.log(graphQLErrors);
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus">
.promo-modal {

  &__buttons {
    display grid
    grid-template-columns 1fr 1fr
    gap 16px
    width 100%
  }
}
</style>
