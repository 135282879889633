import Vue from "vue";
import Vuex from "vuex";
import BUY from "gql/OrderCreate.graphql";
import LIST from "gql/books.graphql";
import CART from "gql/carts.graphql";
import CART_TOGGLE from "gql/CartCreateOrDelete.graphql";
import USER_BOOKS from "gql/my_books.graphql";

Vue.use(Vuex);
export default function createStore(state) {
  return new Vuex.Store({
    state() {
      return (
        state || {
          cart: [],
          books: [],
          book_item: [],
          promocodes: [],
          _modals: [],
          user: null,
          user_books: [],
        }
      );
    },
    actions: {
      async books({ state }, { apollo, query = "" }) {
        await apollo
          .query({
            query: LIST,
            variables: {
              title: query,
            },
          })
          .then(({ data }) => {
            state.books = data.books;
          })
          .catch(() => {});
      },
      async user_books({ state }, { apollo }) {
        await apollo
          .query({
            query: USER_BOOKS,
            fetchPolicy: "no-cache",
            context: {
              headers: {
                Authorization: "Bearer " + state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            state.user_books = data.my_books.map((b) => b.id);
          })
          .catch(() => {});
      },
      async cart({ state }, { apollo }) {
        await apollo
          .query({
            query: CART,
            fetchPolicy: "no-cache",
            context: {
              headers: {
                Authorization: "Bearer " + state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            state.cart = data.carts.map((c) => c.book);
          })
          .catch(() => {});
      },
      async toggle_cart({ state }, { apollo, id }) {
        await apollo
          .mutate({
            mutation: CART_TOGGLE,
            variables: {
              books: [id],
            },
            context: {
              headers: {
                Authorization: "Bearer " + state.apollo_token,
              },
            },
          })
          .then(() => {})
          .catch(() => {});
      },
      async buy({ state }, { apollo, id, promocode }) {
        await apollo
          .mutate({
            mutation: BUY,
            variables: {
              cart: !id,
              book_id: id,
              promocode: promocode,
            },
            context: {
              headers: {
                Authorization: "Bearer " + state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.OrderCreate) {
              location.replace(data.OrderCreate.payment_url);
            }
          });
      },
    },
  });
}
