import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
      meta: {
        showSearch: true,
      },
    },
    {
      path: "/book/:id?",
      name: "book",
      component: () => import("./views/book/open.vue"),
      // props: (route) => ({ id: route.params.id }),
      meta: {
        showSearch: false,
      },
    },
    {
      path: "/buying-on-apple",
      name: "apple",
      component: () => import("./views/static/buying-on-apple.vue"),
      meta: {
        showSearch: false,
      },
    },
    {
      path: "/oauth",
      name: "oauth",
      component: () => import("./views/static/OauthPage.vue"),
      meta: {
        showSearch: false,
      },
    },
    {
      path: "/promocodes",
      name: "promocodes",
      component: () => import("./views/promocodes/index.vue"),
      meta: {
        showSearch: false,
      },
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
    // {
    //   path: "/account",
    //   name: "account",
    //   component: () => import("./views/auth/info/index.vue"),
    //   meta: {
    //     authRequired: true,
    //   },
    // },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
