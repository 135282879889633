<template>
  <div class="m login-modal">
    <div class="m__header">
      <h3 class="m__title">Войти или создать профиль</h3>
      <button type="button" class="m__close" @click="$emit('close')">
        <IconComponent category="linear" name="add" />
      </button>
    </div>
    <div class="m__body login-modal__body">
      <div class="login-modal__inputs" v-if="step === 0">
        <span class="login-modal__inputs-title">Контактный телефон</span>
        <div class="login-modal__inputs-list">
          <InputComponent title="Код" v-model="numberCode" placeholder="+7" mask="+###" />
          <InputComponent v-model="number" :error="error" title="Номер телефона" mask="(###) ### - ## - ##" />
        </div>
      </div>
      <template v-if="step === 1">
        <span>{{ confirmTitle }}</span>
        <InputComponent
          v-model="code"
          :error="error"
          mask="####"
          title="Последние 4 цифры номера"
          autofocus
        />
      </template>
      <div class="m__col login-modal__footer">
        <button
          type="button"
          class="btn btn--md btn--main"
          v-if="step === 0"
          @click="userLogin"
          :disabled="loading"
        >
          <IconComponent name="loading" category="default" v-if="loading" />
          <template v-else>Получить код</template>
        </button>
        <button
          type="button"
          class="btn btn--md btn--main"
          v-if="step === 1"
          @click="phoneConfirm"
          :disabled="loading"
        >
          <LoadingIndicator title="Подтверждение" v-if="loading" />
          <template v-else>Подтвердить</template>
        </button>
        <SocialsRegisterComponent />
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import USER_LOGIN from "gql/auth/UserLoginViaPhone.graphql";
import USER_PHONE_CONFIRM from "gql/auth/UserPhoneConfirm.graphql";
import axios from "axios";
import IconComponent from "components/IconComponent.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import SocialsRegisterComponent from "components/SocialsRegisterComponent.vue";

// step 0 - Ввод номера телефона
// step 1 - Ввод кода из смс
export default {
  name: "LoginPhoneModal",
  data() {
    return {
      step: 0,
      numberCode: "+7",
      number: null,
      code: null,
      loading: false,
      error: null,
      confirmTitle: "Вам поступит звонок от робота.\n Необходимо ввести 4 последние цифры входящего номера",
    };
  },
  methods: {
    userLogin() {
      if (!this.loading) {
        if (this.number) {
          this.loading = true;
          this.error = null;
          this.$apollo
            .mutate({
              mutation: USER_LOGIN,
              variables: {
                phone: this.numberCode + this.number.replace(/\D+/g, ""),
                locale: "ru",
              },
            })
            .then(() => {
              this.loading = false;
              this.step = 1;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.error = "Введите номер телефона";
        }
      }
    },
    phoneConfirm() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: USER_PHONE_CONFIRM,
            variables: {
              code: parseInt(this.code),
            },
          })
          .then(({ data }) => {
            if (data && data.UserPhoneConfirm) {
              this.$store.state.user = data.UserPhoneConfirm.user;
              this.$store.state.apollo_token = data.UserPhoneConfirm.token;
              // Сохраняю токен в сессии
              axios({
                method: "post",
                url: "/token",
                data: {
                  token: data.UserPhoneConfirm.token,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then(() => {
                  // this.$store.state._modals = [];
                  location.reload();
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          })
          .catch(() => {
            this.loading = false;
            this.error = "Введен неправильный код подтверждения";
          });
      }
    },
  },
  components: { SocialsRegisterComponent, LoadingIndicator, IconComponent, InputComponent },
};
</script>

<style lang="stylus">
.login-modal {
  &__inputs {
    display grid
    grid-gap 4px
    width 100%
  }

  &__inputs-list {
    display flex
    align-items flex-start
    gap 8px
    width 100%

    .input__container:first-child {
      width 110px
    }

    .input__container:last-child {
      width 100%
    }
  }

  &__inputs-title {
    display flex
    align-items center
    gap 8px
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--main);
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
