<template>
  <div id="app">
    <HeaderComponent />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <ModalComponent />
  </div>
</template>

<script>
import HeaderComponent from "components/HeaderComponent.vue";
import ModalComponent from "components/modals/index.vue";

export default {
  name: "App",
  components: { ModalComponent, HeaderComponent },
  metaInfo: {
    title: "MyLib - эксклюзивные книги с арабского языка на русский язык",
    titleTemplate: "%s | MyLib",
    meta: [
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "MyLib, эксклюзивные книги, арабская литература, перевод на русский, книга Контент купить онлайн, семейная тематика,  Самые счастливые супруги в мире купить онлайн, 19 основ купить онлайн, 45 основ купить онлайн, 700 идей по воспитанаю купить онлайн, 700 идей, Самые счастливые супруги, 45 основ, 19 основ, Ильяс Амирагаев, приложение Ильяса Амирагаева, контент, a nordan книга, Майлиб, май либ",
      },
      {
        vmid: "description",
        name: "description",
        content:
          "Откройте для себя эксклюзивные книги с арабского языка на русский язык на платформе MyLib. Купите книги онлайн и дайте себе возможность окунуться в  уникальную коллекцию книг онлайн-библиотеки MyLib.",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "MyLib - эксклюзивные книги с арабского языка на русский язык",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "Откройте для себя эксклюзивные книги с арабского языка на русский язык на платформе MyLib. Купите книги онлайн и дайте себе возможность окунуться в  уникальную коллекцию книг онлайн-библиотеки MyLib.",
      },
      {
        vmid: "og:image",
        name: "og:image",
        content: "https://mylib.store/static/images/logo.png",
      },
    ],
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
