<template>
  <header class="header" :class="{ 'header--absolute': $route.name === 'home' }">
    <router-link :to="{ name: 'home' }" class="header__logo" title="MyLib">
      <IconComponent name="logo" category="default" />
    </router-link>
    <nav v-if="showSearch" class="header__nav">
      <InputComponent v-model.trim="query" title="Поиск книги">
        <template v-slot:action>
          <IconComponent name="loading" category="default" v-if="loading" />
          <IconComponent name="search-normal" category="outline" v-else />
        </template>
      </InputComponent>
    </nav>
    <div class="header__store">
      <a href="https://apps.apple.com/ru/app/mylib/id1631302796?l=en" target="_blank">
        <IconComponent name="app-store" category="default" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.app.bookstore&hl=ru&gl=US" target="_blank">
        <IconComponent name="google-play" category="default" />
      </a>
    </div>
    <div class="header__user">
      <button type="button" class="btn btn--main" v-if="!$store.state.user" @click="login">Войти</button>
      <button type="button" class="header__cart" v-if="$store.state.user" @click="showCart">
        <span v-if="$store.state.cart.length">{{ $store.state.cart.length }}</span>
        <IconComponent name="shopping-cart" category="outline" />
      </button>
      <a v-if="$store.state.user" href="#" @click.prevent="logout">
        <IconComponent name="logout" category="outline" />
      </a>
    </div>
  </header>
</template>

<script>
import IconComponent from "./IconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import CartModal from "components/modals/components/CartModal.vue";
import axios from "axios";
import InputComponent from "components/inputs/InputComponent.vue";

export default {
  name: "HeaderComponent",
  data: () => ({
    query: "",
    loading: false,
    debounce: null,
  }),
  computed: {
    showSearch() {
      return this.$route.meta.showSearch;
    },
    userName() {
      let fio = "Личный кабинет";
      if (this.$store.state.user) {
        if (this.$store.state.user.surname) {
          return `${this.$store.state.user.surname} ${this.$store.state.user.name}`;
        } else if (this.$store.state.user.name) {
          return `${this.$store.state.user.name}`;
        }
      }
      return fio;
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    query() {
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$store.dispatch("books", { apollo: this.$apollo, query: this.query }).then(() => {
          this.loading = false;
        });
      }, 500);
    },
  },
  methods: {
    showCart() {
      if (!this.user) {
        this.login();
        return;
      }
      if (!this.$store.state.cart.length) {
        alert("В корзине ничего нет");
        return;
      }
      this.$store.state._modals.push({
        component: CartModal,
      });
    },
    login() {
      this.$store.state._modals.push({
        component: LoginModal,
      });
    },
    logout() {
      if (confirm("Вы уверены?")) {
        axios({
          method: "post",
          url: "/token",
          data: {
            token: undefined,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }).then(() => {
          location.reload();
        });
      }
    },
  },
  components: {
    InputComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.header {
  padding 30px
  display flex
  gap 30px
  align-items center
  width 100%
  +below(860px) {
    gap 16px
    flex-wrap wrap
  }
  +below(560px) {
    padding 16px
  }
  +below(450px) {
    justify-content center
  }

  &__nav {
    width 100%
    +below(860px) {
      order 5
    }
  }

  &__logo {
    display inline-flex
    flex-shrink 0
    width 60px
    height 60px
    +below(860px) {
      order 1
      margin-right auto
      margin-left 0
    }
    .icon {
      width 100%
      height 100%
    }
  }

  &__user {
    display flex
    align-content center
    gap 16px
    margin-left auto
    +below(860px) {
      order 4
    }
    +below(450px) {
      order 3
    }

    span,
    a {
      color var(--dark)

      .icon {
        width 24px
        height 24px

        svg {
          transform rotate(180deg)
        }
      }
    }
  }

  &__cart {
    background none
    border none
    padding none
    align-items center
    justify-content center
    position relative
    cursor pointer
    +below(860px) {
      order 3
    }
    +below(450px) {
      order 2
    }

    .icon {
      width 24px
      height 24px
    }

    span {
      background var(--green)
      font-size 0.75em
      border-radius 100%
      width 16px
      height 16px
      absolute right top
      transform translateX(50%) translateY(-50%)
      color var(--white)
      display inline-flex
      align-items center
      justify-content center
    }
  }

  &__right {
    margin-left auto
    display flex
    gap 16px
  }

  &__store {
    display flex
    gap 16px
    width 100%
    max-width 240px
    flex-shrink 0
    align-content center
    +below(860px) {
      order 2
    }
    +below(450px) {
      max-width 280px
      order 4
    }

    a {
      height auto

      .icon {
        width 100%
        height 100%
      }
    }
  }
}
</style>
