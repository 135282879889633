<template>
  <div class="m">
    <div class="m__header">
      <h1 class="m__title">Войти</h1>
      <button class="m__close" type="button" @click="$emit('close')">
        <IconComponent category="linear" name="add" />
      </button>
    </div>
    <form class="m__body" @submit.prevent="login">
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        autofocus
        title="E-mail"
        type="email"
      />
      <InputComponent
        v-model.trim="form.password.value"
        :errors="form.password.errors"
        :type="form.password.show ? 'text' : 'password'"
        title="Пароль"
        @submit="form.password.show = !form.password.show"
      >
        <template v-slot:action>
          <IconComponent category="linear" name="eye-slash" v-if="form.password.show" />
          <IconComponent category="linear" name="eye" v-else />
        </template>
      </InputComponent>
      <div class="m__col">
        <button class="btn btn--main" type="submit" :disabled="loading">
          <IconComponent name="loading" category="default" v-if="loading" />
          <span v-show="!loading">Войти</span>
        </button>
        <span class="m__note">
          Пользуясь данной формой вы соглашаетесь с нашими
          <a href="#">условиями пользования</a>
        </span>
      </div>
      <div class="m__col">
        <a class="m__link" href="#" @click.prevent="phoneLogin">Войти по номеру телефона</a>
        <!--        <a class="m__link">Забыли пароль?</a>-->
      </div>
      <SocialsRegisterComponent />
    </form>
  </div>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import LOGIN from "gql/auth/UserLogin.graphql";
import axios from "axios";
import LoginPhoneModal from "components/modals/components/LoginPhoneModal.vue";
import SocialsRegisterComponent from "components/SocialsRegisterComponent.vue";

export default {
  name: "LoginModalComponent",
  data() {
    return {
      loading: false,
      form: {
        email: {
          errors: [],
          value: null,
        },
        password: {
          show: false,
          errors: [],
          value: null,
        },
      },
    };
  },
  methods: {
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    login() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors();
        this.$apollo
          .mutate({
            mutation: LOGIN,
            variables: {
              email: this.form.email.value,
              password: this.form.password.value,
              locale: "ru",
            },
          })
          .then(({ data }) => {
            if (data && data.UserLogin) {
              this.$store.state.user = data.UserLogin.user;
              this.$store.state.apollo_token = data.UserLogin.token;
              // Сохраняю токен в сессии
              axios({
                method: "post",
                url: "/token",
                data: {
                  token: data.UserLogin.token,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then(() => {
                  // this.$store.state._modals = [];
                  location.reload();
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          })
          .catch(({ graphQLErrors }) => {
            this.parseGqlErrors(graphQLErrors);
            this.loading = false;
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    phoneLogin() {
      this.$store.state._modals.push({
        component: LoginPhoneModal,
      });
    },
  },
  components: {
    SocialsRegisterComponent,
    InputComponent,
    IconComponent,
    LoadingIndicator,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/modal.styl"
</style>
